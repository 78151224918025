import React from "react";
import JobMain from "./JobMain";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/Breadcrumb";

const Job = () => {
  return (
    <>
      <Header
        parentMenu="page"
        headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
      />
      <div className="">
      <Breadcrumb pageTitle="Careers" breadcrumbsImg="Career progress.svg" paddingTopBottom="breadcrumb__space-2" />
      </div>
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <JobMain />
          <Footer footerClass="footer__area footer__plr footer__plr-3" />
        </div>
      </div>
    </>
  );
};

export default Job;
