import React, { useState } from "react";
import { handleFormSubmit } from "../service/handleFormSubmit";
import Toast from "../../components/Toast";

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await handleFormSubmit(formData)

    if (res.success === true) {
      setFormData({ name: '', email: '', message: '' });
      setMessage('Form has been Sent Successfully!');
      setBackgroundColor('#6BF89C');
      setShowToast(true);
      return;
    } else {
      setMessage('Error sending form!');
      setBackgroundColor('red');
      setShowToast(true);
      return;
    }
  };


  return (
    <div className="contact__area contact__mlr fix pb-90">
        <Toast
          message={message}
          showToast={showToast}
          setShowToast={setShowToast}
          backgroundColor={backgroundColor}
        />
      <div className="container-fluid">
        <div className="row align-items-center">
          <div
            className="col-xl-6 col-lg-6 wow animate__fadeInRight"
            data-wow-duration="1.1s"
          >
            <div className="contact__section-title pb-10">
              <h4 className="char-anim section-subtitle">Get In Contact</h4>
              <h3 className="section-title char-anim">
                Let's talk about your project!
              </h3>
            </div>
            <div className="contact__text">
              <p className="char-anim-2">
                We would be happy to hear from you, please fill in the form below.
              </p>
            </div>
            <div className="contact__form-2">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div
                    className="col-sm-6 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="contact__from-2-input">
                      <input
                        required
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Your Name"
                      />
                    </div>
                  </div>
                  <div
                    className="col-sm-6 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="contact__from-2-input">
                      <input
                        required
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Your Email"
                      />
                    </div>
                  </div>
                  <div
                    className="col-sm-12 wow animate__fadeInUp"
                    data-wow-duration="1.1s"
                  >
                    <div className="contact__from-2-input">
                      <textarea
                        required
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div
                  className="contact__button wow animate__fadeInUp"
                  data-wow-duration="1.1s"
                >
                  <button
                    className="main-btn tp-btn-hover alt-color-black"
                    type="submit"
                  >
                    <span>Send Request</span>
                    <b></b>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 wow animate__fadeInRight contact__borderLeft"
            data-wow-duration="1.1s"
          >
            <h3>What Happens Next</h3>
            <ol className="contact__ol">
              <li className="contact__li">Our business development team contacts you within two working days</li>
              <li className="contact__li">A discovery session is held to understand your project requirements</li>
              <li className="contact__li">All the information shared is protected under the mNDA</li>
              <li className="contact__li">Our technical and business development teams analyze the scope of your project and share the best way forward in a proposal</li>
              <li className="contact__li">A software development agreement is signed based on mutually agreed terms of the proposal</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
