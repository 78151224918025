import React, { useEffect } from 'react';

const Toast = ({ message, showToast, setShowToast, backgroundColor }) => {
    useEffect(() => {
        if (showToast) {
          // Automatically hide the toast after 3 seconds
          const timer = setTimeout(() => setShowToast(false), 3000);
          return () => clearTimeout(timer);
        }
      }, [showToast]);
    return (
        <div className={`toast-container ${showToast ? 'show' : ''}`}>
            <div className="toast" style={{ backgroundColor: backgroundColor }}>
                <div className="toast-message">{message}</div>
            </div>
        </div>
    );
};

export default Toast;