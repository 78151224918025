import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';



import SwiperCore, { Navigation, Pagination } from 'swiper';

import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/main.scss';

// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';

import './index.scss'
SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);



createRoot(document.getElementById('root')).render(
	<BrowserRouter>
		
		<App />
		<ScreenInfo />
	</BrowserRouter>
);


function ScreenInfo(){
    const __DEV__=!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
	const [screen,setScreen]=useState(getScreenObject())

	useEffect(()=>{

		window.addEventListener("resize",(w)=>{
			console.log("resize",window.screen.width)
			setScreen(getScreenObject())
		})
	},[])
	if (!__DEV__){
		return null;
	}

	return (<div className='screenInfo'>
		<span>Screen Info: {screen.innerWidth}px x {screen.innerHeight}px</span>
	</div>)
}

function getScreenObject(){
 const {innerHeight,innerWidth} =window
 return {innerHeight,innerWidth}
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
