import React from "react";
import emailjs from "emailjs-com";

import { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase";
import { v4 } from "uuid";

import shapeImg1 from "../../assets/img/contact/ct-shape-1.png";
import shapeImg2 from "../../assets/img/contact/ct-shape-2.png";
import shapeImg3 from "../../assets/img/contact/ct-shape-3.png";
import shapeImg4 from "../../assets/img/contact/ct-shape-4.png";
import Toast from "../../components/Toast";
import { handleFormSubmit } from "../service/handleFormSubmit";

const JobForm = (props) => {
  const { itemClass } = props;

  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');

  const uploadFile = (e) => {
    const fileUpload = e.target.files[0]
    if (fileUpload == null) return;
    const imageRef = ref(storage, `resumes/${fileUpload.name + v4()}`);
    console.log(imageRef);
    uploadBytes(imageRef, fileUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        console.log(url);
        setUserData((prevUserData) => ({
          ...prevUserData,
          resumeURL: url
        }));
      });
    });
  };


  //Contact Form Data Upload
  const [userData, setUserData] = useState({
    Name: "",
    phone: "",
    email: "",
    position: "",
    message: "",
    resumeURL: "",
    resumeName: "",
  });

  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setUserData({ ...userData, [name]: value });
  };

  const submitData = async (event) => {
    event.preventDefault();
    const { Name, phone, email, position, message, resumeURL } = userData;

    if (Name && phone && email && position && message && resumeURL) {
      const res = await handleFormSubmit(userData)
      if (res.success) {
        setShowToast(true);
        setMessage('Form has been Sent Successfully!');
        setBackgroundColor('green');
        setUserData(
          {
            Name: "",
            phone: "",
            email: "",
            position: "",
            message: "",
            resumeURL: "",
            resumeName: "",
          }
        );
        return
      } else {
        setShowToast(true);
        setMessage('Oops! Something went wrong.');
        setBackgroundColor('red');
        return
      }
    } else {
      alert("plz fill the data");
    }
  };

  const handleUploadFile = (event) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      resumeName: event.target.files[0].name
    }));
    uploadFile(event)
  };

  return (
    <div
      className={
        itemClass ? itemClass : "contact__area contact__plr p-relative fix"
      }
    >
      <Toast
        message={message}
        showToast={showToast}
        setShowToast={setShowToast}
        backgroundColor={backgroundColor}
      />
      <div className="contact__shape-1 d-none d-lg-block">
        <img src={shapeImg1} alt="" />
      </div>
      <div className="contact__shape-2 d-none d-md-block">
        <img src={shapeImg2} alt="" />
      </div>
      <div className="contact__shape-3 d-none d-md-block">
        <img src={shapeImg3} alt="" />
      </div>
      <div className="contact__shape-4 d-none d-md-block">
        <img src={shapeImg4} alt="" />
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-8 col-lg-7">
            <div className="contact__section-title pb-10">
              <h4 className="section-subtitle char-anim">Apply Today</h4>
              <h3 className="section-title char-anim">
                Start your dream career
              </h3>
            </div>
            <div className="contact__text">
              <p className="char-anim-2">
                Join a team where your ideas matter.{" "}
              </p>
            </div>
            <div
              className="contact__form wow animate__fadeInUp"
              data-wow-duration="1.1s"
            >
              <form action="#" >
                <div className="row">
                  <div className="col-sm-6">
                    <div className="postbox__contact-input">
                      <input
                        type="text"
                        placeholder="Name"
                        name="Name"
                        value={userData.Name}
                        onChange={postUserData}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="postbox__contact-input">
                      <input type="email" placeholder="Email"
                        name="email"
                        value={userData.email}
                        onChange={postUserData} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="postbox__contact-input">
                      <input type="tel" placeholder="Phone Number"
                        name="phone"
                        value={userData.phone}
                        onChange={postUserData} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="postbox__contact-input">
                      <input type="text" placeholder="Position" name="position" value={userData.position} onChange={postUserData} />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="postbox__contact-textarea">
                      <textarea placeholder="Message" name="message" value={userData.message} onChange={postUserData} ></textarea>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="tp-btn-hover alt-color">
                      <label className="custom-file-upload">
                        <input
                          type="file"
                          id="resumeInput"
                          onChange={(event) => { handleUploadFile(event) }}
                        />
                        {userData.resumeName ? userData.resumeName : "Upload Resume"}
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="contact__button wow animate__fadeInUp" data-wow-duration="1.1s">
              <button className={`main-btn-sm ${!userData.resumeURL && 'bg-black bg-opacity-10'}`} onClick={submitData} disabled={!userData.resumeURL}>Submit</button>
            </div>
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="contact__left-side">
              <div
                className="contact__item d-flex align-items-center wow animate__fadeInUp"
                data-wow-duration="1.1s"
              >
                <div className="contact__icon">
                  <span>
                    <a href="#">
                      <i className="fal fa-phone-alt"></i>
                    </a>
                  </span>
                </div>
                <div className="contact__content">
                  <h4 className="contact__title-sm">Contact</h4>
                  <span>
                    <a href="tel:+92-334-1402463">+92-334-1402463</a>
                  </span>
                </div>
              </div>
              <div
                className="contact__item d-flex align-items-center wow animate__fadeInUp"
                data-wow-duration="1.1s">
                <div className="contact__icon">
                  <span>
                    <a href="#">
                      <i className="fal fa-envelope-open-text"></i>
                    </a>
                  </span>
                </div>
                <div className="contact__content">
                  <h4 className="contact__title-sm">Email</h4>
                  <span>
                    <a href="mailto:contact@sortup.dev">contact@sortup.dev</a>
                  </span>
                </div>
              </div>
              <div
                className="contact__item d-flex align-items-center wow animate__fadeInUp"
                data-wow-duration="1.1s"
              >
                <div className="contact__icon">
                  <span>
                    <a href="#">
                      <i className="fal fa-map-marker-check"></i>
                    </a>
                  </span>
                </div>
                <div className="contact__content">
                  <h4 className="contact__title-sm">Your location</h4>
                  <span>
                    Remote
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobForm;
