import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ContactSection from "../home-3/ContactSection";

const Contact = () => {
    return (
        <>
            <Header
                parentMenu="contact"
                headerClass="header__area header__transparent-2 header-width header-bg-color-2 header__mlr header__mt"
            />
            <div
                className="breadcrumb__area breadcrumb__plr breadcrumb__space-2 breadcrumb__bg breadcrumb__overlay p-relative fix "  >
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                            <div className="breadcrumb__content z-index">
                                <h3
                                    className="breadcrumb__title char-anim wow animate__fadeInUp"
                                    data-wow-duration="1.1s"
                                >
                                    Let’s get started with your project
                                </h3>
                                <div
                                    className="breadcrumb__button wow animate__fadeInUp"
                                    data-wow-duration="1.1s"
                                >
                                    Whether you’re looking to build something new and exciting or need our help with an ongoing project, we’ve got you covered.
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-xxl-6 col-xl-6 col-lg-6 wow animate__fadeInRight"
                            data-wow-duration="1.1s"
                        >
                            <div className="text-end breadcrumb__hide">
                                <img
                                    src="contact us.svg"
                                    alt=""
                                    width={"60%"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="smooth-wrapper">
                <div id="smooth-content">
                    <div className="">
                        <ContactSection />
                    </div>
                    <Footer footerClass="footer__area footer__plr footer__plr-3" />
                </div>
            </div>
        </>
    );
};

export default Contact;
