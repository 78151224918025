import React from 'react';

const Work = () => {
  return (
    <div className="work-3__area work-3__inner-wrapper work-3__plr pb-4">
      <h1 className="text-center py-5">Our benefits</h1>
      <div className="container-fluid">
        <div className="row row-cols-xl-3 row-cols-md-2 justify-content-center">
          <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-1 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                  <div className="work-3__icon icon-color-1">
                    <span><i className="fas fa-lightbulb-on"></i></span>
                  </div>
                  <div className="work-3__title">
                    <h4 className="work-3__section-title">Flexible Work Hours</h4>
                  </div>
                </div>
                <div className="work-3__right-text">
                  <p>Customize your schedule with Flexible Work Hours. Achieve work-life harmony on your terms.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-2 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                <div className="work-3__icon icon-color-1">
                  <span><i className="fas fa-cogs"></i></span>
                </div>
                <div className="work-3__title">
                  <h4 className="work-3__section-title">Full Work From Home</h4>
                </div>
                </div>
                <div className="work-3__right-text">
                <p>Work entirely from home hassle-free with Full Work From Home. Enjoy productivity and comfort, no commute required.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-3 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                <div className="work-3__icon icon-color-1">
                  <span><i className="fas fa-chart-bar"></i></span>
                </div>
                <div className="work-3__title">
                  <h4 className="work-3__section-title">Training & Certifications</h4>
                </div>
                </div>
                <div className="work-3__right-text">
                <p>Upgrade your skills with Training & Certifications. Propel your career forward with our diverse programs.</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-4 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                <div className="work-3__icon icon-color-1">
                  <span><i className="fas fa-lightbulb-on"></i></span>
                </div>
                <div className="work-3__title">
                  <h4 className="work-3__section-title"><a href="#">Business of Promotion</a></h4>
                </div>
                </div>
                <div className="work-3__right-text">
                <p>Business building it before the tab providet best aency srvice management, Payroll.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-5 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                <div className="work-3__icon icon-color-1">
                  <span><i className="fas fa-lightbulb-on"></i></span>
                </div>
                <div className="work-3__title">
                  <h4 className="work-3__section-title"><a href="#">This Promotion Agncy</a></h4>
                </div>
                </div>
                <div className="work-3__right-text">
                <p>Business building it before the tab providet best aency srvice management, Payroll.</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Work;