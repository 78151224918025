import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Link as ScrollLink, animateScroll } from 'react-scroll';

const MenuItems = (props) => {
    const { parentMenu } = props;

    const location = useLocation();

    const [home, setHome] = useState(false)
	const [about, setAbout] = useState(false)
	const [service, setService] = useState(false)
	const [page, setPage] = useState(false)
	const [blog, setBlog] = useState(false)

	const openMobileMenu = menu => {

		if (menu === 'home') {
			setHome(!home)
			setAbout(false)
			setService(false)
			setPage(false)
			setBlog(false)
		}
		else if (menu === 'about') {
			setHome(false)
			setAbout(!about)
			setService(false)
			setPage(false)
			setBlog(false)
		}
		else if (menu === 'service') {
			setHome(false)
			setAbout(false)
			setService(!service)
			setPage(false)
			setBlog(false)
		}
		else if (menu === 'page') {
			setHome(false)
			setAbout(false)
			setService(false)
			setPage(!page)
			setBlog(false)
		}
		else if (menu === 'blog') {
			setHome(false)
			setAbout(false)
			setService(false)
			setPage(false)
			setBlog(!blog)
		}
	};

    return (
        <>
            <li className={parentMenu === 'home'  ? "":""}><Link to="/">Home</Link>
                {/* <ul className={home ? "submenu submenu-open" : "submenu"}>
                    <li className={location.pathname === "/" ? "active" : ""}><Link to="/">Creative Agency</Link></li>
                    <li className={location.pathname === "/home-2" ? "active" : ""}><Link to="/home-2">Personal Portfolio</Link></li>
                    <li className={location.pathname === "/home-3" ? "active" : ""}><Link to="/home-3">Startup Business</Link></li>
                    <li className={location.pathname === "/home-4" ? "active" : ""}><Link to="/home-4">Digital Agency</Link></li>
                    <li className={location.pathname === "/home-5" ? "active" : ""}><Link to="/home-5">IT Service Agency</Link></li>
                </ul>
                <a className={home ? 'mean-expand mean-expand mean-clicked' : 'mean-expand'} href="#" onClick={() => { openMobileMenu('home'); }}><i className="fal fa-plus"></i></a> */}
            </li>
			<li>
				{location.pathname === '/' ? (
					<ScrollLink to="portfolio" smooth={true} duration={500} offset={-50} className={parentMenu === 'Portfolio' ? 'active' : ''} style={{ cursor: 'pointer' }}>
						Portfolio
					</ScrollLink>
				
				) : (
					<></>
					/*
						<Link to="/">
							Portfolio
						</Link>

					*/
				)}
			</li>
            {/* <li className={parentMenu === 'about'  ? "":""}><Link to="/about-us">About</Link>
                <ul className={about ? "submenu submenu-open" : "submenu"}>
                    <li className={location.pathname === "/about-me" ? "active" : ""}><Link to="/about-me">About Me</Link></li>
                    <li className={location.pathname === "/about-us" ? "active" : ""}><Link to="/about-us">About US</Link></li>
                </ul>
                <a className={about ? 'mean-expand mean-expand mean-clicked' : 'mean-expand'} href="#" onClick={() => { openMobileMenu('about'); }}><i className="fal fa-plus"></i></a>
            </li> */}
            {/* <li className={parentMenu === 'service' ? "":"" }><Link to="/service-details">Services</Link>
                
            </li> */}
            
            <li className={location.pathname === "/careers" ? "active" : ""}><Link to="/careers">Careers</Link></li>
            <li className={location.pathname === "/contact" ? "active" : ""}><Link to="/contact">Contact Us</Link></li>
        </>
    );
}

export default MenuItems;