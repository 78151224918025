import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SingleTestimonialThree from '../../components/Testimonial/SingleTestimonialThree';

import shapeImg1 from '../../assets/img/testimonial/testi-shape-12.png';
import shapeImg2 from '../../assets/img/testimonial/testi-shape-6.png';
import shapeImg3 from '../../assets/img/slider/shape-27.png';
import shapeImg4 from '../../assets/img/slider/shape-27.png';

import testimonialImg1 from '../../assets/img/testimonial/testi-4.png';

const Testimonial = () => {
    const sliderOption = {
        pagination: {
          el: '.testimonial-3-slider-dots',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        loop: true,
        spaceBetween: 30,
        slidesPerView: '1'
    }

    return (
        <div className="testimonial-3__area testimonial-3__space p-relative">
            <div className="testimonial-3__shape-1 d-none d-xl-block">
                <img src={shapeImg1} alt="" />
            </div>
            {/* <div className="testimonial-3__shape-2 d-none d-lg-block">
                <img src={shapeImg2} alt="" />
            </div>
            <div className="testimonial-3__shape-3 d-none d-xl-block">
                <img src={shapeImg3} alt="" />
            </div>
            <div className="testimonial-3__shape-4 d-none d-lg-block">
                <img src={shapeImg4} alt="" />
            </div> */}
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="testimonial-3__section-box text-center pb-40">
                            <h5 className="section-subtitle title-anim wow animate__fadeInUp" data-wow-duration=".6s">What Our Clients Say</h5>
                            <h4 className="section-title title-anim wow animate__fadeInUp" data-wow-duration=".7s">Our clients trust us to turn their ideas into digital reality, and we take that trust seriously.</h4>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-12 wow animate__fadeInLeft" data-wow-duration="1.1s">
                        <div className="testimonial-3__main-img p-relative text-center">
                            <img src={testimonialImg1} alt="" />
                            <div className="testimonial-3__icon">
                                <span><i className="fas fa-quote-right"></i></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 wow animate__fadeInRight" data-wow-duration="1.1s">
                        <div className="testimonial-2__slider-wrapper">
                            <div className="swiper-container testimonial-3__active">
                                <Swiper {...sliderOption} className="swiper-wrapper" autoplay={{ delay: 3000, disableOnInteraction: false,}}>
                                    <SwiperSlide className="swiper-slide">
                                        <SingleTestimonialThree
                                            Title="Stephen"
                                            Designation="Founder/CEO"
                                            Description="Overall he seems committed to the project, I appreciated his hardwork and dedication."
                                        />
                                    </SwiperSlide>
                                    {/* <SwiperSlide className="swiper-slide">
                                        <SingleTestimonialThree
                                            Title="Huzaima"
                                            Designation="Founder/CEO"
                                            Description="I had a great experience working with the Sortup group, with Shahid Sheharyar, Yousaf Khan, and the rest of the team."
                                        />
                                    </SwiperSlide> */}
                                    {/* <SwiperSlide className="swiper-slide">
                                        <SingleTestimonialThree
                                            Title="Jawad"
                                            Designation="Founder/CEO"
                                            Description="They were very professional, willing to adapt to my schedule, and offered multiple versions of the website to ensure that I was satisfied with the final version."
                                        />
                                    </SwiperSlide> */}
                                    <SwiperSlide className="swiper-slide">
                                        <SingleTestimonialThree
                                            Title="Lories Khoury"
                                            Designation="Founder/CEO"
                                            Description="Shahid is the most responsive and reliable Lead Software Engineer! I could not have had a better person on my team!"
                                        />
                                    </SwiperSlide>
                                    {/* <SwiperSlide className="swiper-slide">
                                        <SingleTestimonialThree
                                            Title="Sturard Barson"
                                            Designation="Manager"
                                            Description=""
                                        />
                                    </SwiperSlide> */}
                                </Swiper>
                            <div className="testimonial-3-slider-dots text-center text-lg-start z-index"></div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonial;