import React from "react";
import { Link } from "react-router-dom";

import SectionTitle from "../../components/SectionTitle";
import SingleService from "../../components/Service";

import shapeImg1 from "../../assets/img/service/sv-shape-1.png";
import shapeImg2 from "../../assets/img/service/sv-shape-2.png";

import serviceIconBg1 from "../../assets/img/service/sv-icon-shape-1.png";
import serviceIconBg2 from "../../assets/img/service/sv-icon-shape-2.png";
import serviceIconBg3 from "../../assets/img/service/sv-icon-shape-3.png";
import serviceIconBg4 from "../../assets/img/service/sv-icon-shape-4.png";
import serviceIconBg5 from "../../assets/img/service/sv-icon-shape-5.png";
import serviceIconBg6 from "../../assets/img/service/sv-icon-shape-6.png";

const Service = () => {
  return (
    <div className="service__area service__space service__mlr grey-bg pt-195 pb-70 p-relative">
      <div className="service__shape-2">
        <img src={shapeImg2} alt="" />
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-xl-12 col-md-9">
            <div className="work__section-box text-center">
              <h5
                className="section-subtitle title-anim wow animate__fadeInUp"
                data-wow-duration="1.1s"
              >
                Partnership Models
              </h5>
              <p>
                Sortup a leading app development company offers custom app
                development services to wide range of industries and businesses.
                Know more about our partnership models.
              </p>
              {/* <h4 className="section-title title-anim wow animate__fadeInUp" data-wow-duration="1.1s">Experts Every Aspect <br/> Service Work.</h4> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SingleService
              itemClass="service__item service__border-1 p-relative fix"
              titleClass="service__title title-color-1"
              btnClass="service__link service__link-color-1"
              serviceIconBg={serviceIconBg1}
              Title="Fixed Price Model"
              Description="In this model, Project scope, cost, and timeline are agreed upon before development begins, providing clients peace of mind that the project will stay within budget. Ideal for clients with a clear vision of their requirements and longer engagement periods."
              serviceIcon="fa fa-lock"
            />
          </div>
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SingleService
              itemClass="service__item service__border-2 p-relative fix"
              titleClass="service__title title-color-2"
              btnClass="service__link service__link-color-2"
              serviceIconBg={serviceIconBg2}
              Title="Hire Dedicated Model"
              Description="The hourly engagement model allows clients to pay for the time spent by app developers on their project. It offers flexibility as clients can begin without detailed specifications and can request updates anytime. Clients have clarity on what they're paying for."
              serviceIcon="fa fa-user"
            />
          </div>
          <div
            className="col-xl-4 col-lg-6 col-md-6 mb-30 wow animate__fadeInUp"
            data-wow-duration="1.1s"
          >
            <SingleService
              itemClass="service__item service__border-3 p-relative fix"
              titleClass="service__title title-color-3"
              btnClass="service__link service__link-color-3"
              serviceIconBg={serviceIconBg3}
              Title="On Site Development Model"
              Description="This model is ideal for on-site development with temporary resources. It ensures cost-effectiveness and face-to-face interaction with developers, promoting timely delivery through continuous communication."
              serviceIcon="fa fa-building"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
