export const handleFormSubmit = async (formData) => {
    try {
      const res = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        body: JSON.stringify({
          access_key: '88b793c3-4b6b-4cdd-a9d1-65b68bd09097',
          ...formData,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
  
      if (res.status === 200) {
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      return { success: false, error: 'An error occurred while submitting the form' };
    }
  };
