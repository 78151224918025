import React from 'react';

const TopHeading = () => {
  return (
    <div className="team__area team__space-3 team__plr grey-bg">
      <div className="container-fluid">
        <div className="row align-items-end justify-content-center mb-60">
          <div className="col-xl-12 col-lg-6 col-md-9 col-sm-10">
            <div className="feature__section-box text-center">
              <h3 className="section-title title-anim wow animate__fadeInUp" data-wow-duration="1.1s">Why Sortup?</h3>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="about-details__content">
                <div className="about-details__text">
                  <p>
                  At Sortup, we believe in turning your ideas into reality by crafting cost-effective software solutions that align perfectly with your unique needs. 
                  Our process begins with a thorough requirement analysis, allowing us to design tailored solutions that bring your vision to life. Once approved, 
                  you can trust Sortup to transform those brilliant ideas into high-end software products that propel your business forward.{" "}
                  </p>
                </div>
                <div className="about-details__content-list">
                <h5 className="about-details__title">Some key Points</h5>
                  <ul>
                    <li>
                      <i className="fal fa-check-square"></i>
                      <span>
                      Sortup values your journey, fostering personal and professional growth.
                      </span>
                    </li>
                    <li>
                      <i className="fal fa-check-square"></i>
                      <span>We invest in your potential, unleashing capabilities for innovative solutions.</span>
                    </li>
                    <li>
                      <i className="fal fa-check-square"></i>
                      <span>
                      Join a team that values diverse voices and open communication.
                      </span>
                    </li>
                    <li>
                      <i className="fal fa-check-square"></i>
                      <span>
                      Explore cutting-edge technologies for a rewarding professional journey.
                      </span>
                    </li>
                    <li>
                      <i className="fal fa-check-square"></i>
                      <span>Achieve goals in a positive, supportive Sortup environment.</span>
                    </li>
                    <li>
                      <i className="fal fa-check-square"></i>
                      <span>Forge your unique career path with Sortup's support and guidance.</span>
                    </li>
                  </ul>
                </div>
                <div className="about-details__text">
                  <p>
                  Language or stack is no barrier when you embark on your software development journey with Sortup. We embrace diversity and ensure that your unique requirements are met, regardless of the technology you prefer. 
                  At Sortup, we believe in breaking down barriers and making technology accessible to all.{" "}
                  </p>
                  <p>
                  Ready to take the next step? Connect with us on LinkedIn or shoot us an email at <i>contact@sortup.dev</i>. 
                  Let's collaborate and turn your software development aspirations into a reality with Sortup!
                  </p>
                </div>
              </div>
          
          
          
          
          
  
        </div>
      </div>
    </div>
  );
}

export default TopHeading;