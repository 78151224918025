
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/img/logo/Sortup-1.png';
import footerBG from '../../assets/img/footer/footer-bg.jpg';
import { handleFormSubmit } from '../../pages/service/handleFormSubmit';
import Toast from '../Toast';

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    const [formData, setFormData] = useState({
        name: "Email Subscription",
        email: "",
        subject: "New Email Submission",
    });
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await handleFormSubmit(formData)
        if (res.success) {
            setShowToast(true);
            setMessage('Email has been Sent Successfully!');
            setBackgroundColor('green');
            setFormData({ email: '' });
            return
        } else {
            setShowToast(true);
            setMessage('Oops! Something went wrong.');
            setBackgroundColor('red');
            return
        }
    }
    return (
        <footer>
            <Toast
                message={message}
                showToast={showToast}
                setShowToast={setShowToast}
                backgroundColor={backgroundColor}
            />
            <div className={footerClass ? footerClass : 'footer__area footer__plr'} style={{ backgroundImage: `url(${footerBG})` }}>
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-xxl-2 col-xl-3 col-lg-2 col-md-2 col-sm-7 mb-70 wow animate__fadeInUp"
                            data-wow-duration="1.1s">
                            <div className="footer__widget footer-col-1">
                                <div className="footer__logo">
                                    <Link to="/">
                                        <img src={footerLogo ? footerLogo : Logo} alt="" />
                                    </Link>
                                </div>
                                <div className="footer__content">
                                    <div className="footer__text">
                                        {/* <p>Sortup turns your innovative ideas into digital realities through cutting-edge solutions.</p> */}
                                    </div>
                                    <div className="footer__number-wrapper d-flex align-items-center">
                                        <div className="footer__number-icon">
                                            <span><i className="fal fa-phone-alt"></i></span>
                                        </div>
                                        <div className="footer__number-info">
                                            <span>Have Any Questions?</span>
                                            <a href="tel:+230045647823">+92-334-1402463</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 col-sm-5 mb-70 wow animate__fadeInUp"
                            data-wow-duration="1.1s">
                            <div className="footer__widget footer-col-2">
                                <h4 className="footer__title"><Link to="/service">Service</Link></h4>
                                <div className="footer__menu-list">
                                <ul>
                                    <li><a href="#">Dedicated Teams & Resources</a></li>
                                    <li><a href="#">Custom Software Solutions</a></li>
                                    <li><a href="#">Web Design</a></li>
                                    <li><a href="#">Web development</a></li>
                                    <li><a href="#">Mobile development</a></li>
                                    <li><a href="#">Cloud Computing</a></li>
                                    <li><a href="#">Data Management</a></li>
                                </ul>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-xxl-3 col-xl-2 col-lg-6 col-md-6 col-sm-5 mb-70 wow animate__fadeInUp"
                            data-wow-duration="1.1s">
                            <div className="footer__widget footer-col-3">
                                <h4 className="footer__title">
                                    <a href="#">Services</a>
                                </h4>
                                <div className="footer__menu-list">
                                    <ul>
                                        <li><a href="#">Dedicated Teams & Resources</a></li>
                                        <li><a href="#">Custom Software Solutions</a></li>
                                        <li><a href="#">Web Design</a></li>
                                        <li><a href="#">Web development</a></li>
                                        <li><a href="#">Mobile development</a></li>
                                        <li><a href="#">Cloud Computing</a></li>
                                        <li><a href="#">Data Management</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-7 mb-70 footer__marginRight wow animate__fadeInUp"
                            data-wow-duration="1.1s">
                            <div className="footer__widget footer-col-4">
                                <h4 className="footer__title"><a href="#">Newsletter</a></h4>
                                <div className="footer__content">
                                    <p>Stay updated with latest technology trends and topics with us.</p>
                                </div>
                                <div className="footer__input">
                                    <form onSubmit={handleSubmit}>
                                        <input type="email" placeholder="Your email" name="email" value={formData.email} onChange={handleChange} />
                                        <button className="subscribe-btn">Subscribe </button>
                                    </form>
                                </div>
                                <div className="footer__social-box">
                                    <span>Social Media:</span>
                                    <Link to="https://www.linkedin.com/company/sortup/" target="_blank"><i className="fab fa-linkedin"></i></Link>
                                    <Link to="https://www.instagram.com/sortup" target="_blank"><i className="fab fa-instagram"></i></Link>
                                    <Link to="https://wa.me/message/SCQRRYFZKQIPG1" target="_blank"><i className="fab fa-whatsapp"></i></Link>
                                    {/* <a href="#"><i className="fab fa-pinterest-p"></i></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copyright__area copyright__plr black-bg-3">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-6 wow animate__slideInUp" data-wow-duration="1.1s">
                            <div className="copyright__text">
                                <span>Copyright Sortup 2024, All Right Reserved</span>
                            </div>
                        </div>
                        <div className="col-md-6 wow animate__fadeInUp" data-wow-duration="1.1s">
                            <div className="copyright__item text-start text-md-end">
                                <span><Link to="/">Home</Link></span>
                                {/* <span><Link to="#">About</Link></span>
                                <span><Link to="#">Services</Link></span> */}
                                <span><Link to="#">Contact us</Link></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
}

export default Footer;