import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import SectionTitle from '../../components/SectionTitle';
import SingleProjectFour from '../../components/Project/SingleProjectFour';

import projectImg1 from '../../assets/img/project/gameon-web1.PNG';
import projectImg2 from '../../assets/img/project/attorney-web1.PNG';
import projectImg3 from '../../assets/img/project/yves-mobile.png';
import projectImg4 from '../../assets/img/project/yves-web1.PNG';
import projectImg5 from '../../assets/img/project/gameon-mobile.png';
import medilyze from '../../assets/img/project/medilyze.png';
import TheraHome from '../../assets/img/project/TheraHome.png';
import gameonui from '../../assets/img/project/gameon-ui.png';
// import projectImg4 from '../../assets/img/project/pro-51.jpg';
// import projectImg5 from '../../assets/img/project/pro-52.jpg';
// import projectImg6 from '../../assets/img/project/pro-53.jpg ';
// import projectImg7 from '../../assets/img/project/pro-54.jpg';
// import projectImg8 from '../../assets/img/project/pro-55.jpg';
// import projectImg9 from '../../assets/img/project/pro-56.jpg';
// import projectImg10 from '../../assets/img/project/pro-57.jpg';
// import projectImg11 from '../../assets/img/project/pro-58.jpg';
// import projectImg12 from '../../assets/img/project/pro-59.jpg';
// import projectImg13 from '../../assets/img/project/pro-60.jpg';
// import projectImg14 from '../../assets/img/project/pro-61.jpg';
// import projectImg15 from '../../assets/img/project/pro-62.jpg';

const Project = () => {
    let tab1 = "Web Development",
        tab2 = "Mobile Development",
        tab3 = "UI/UX Designs"
       // tab4 = "Branding Design"
        const tabStyle = 'nav nav-pill justify-content-center mb-3';

    return (
        <div className="project-4__area project-4__space grey-bg-2">
            <div className="container-fluid">
                <Tabs>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="project-4__section-box text-center mb-30">
                                <h5 className="section-subtitle title-anim">Case Studies</h5>
                                <h4 className="section-title title-anim">We shed a light on our work and <br/>what goes behind the development.</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="project-4__project-tab">
                                <TabList className={tabStyle}>
                                    <Tab><button>{tab1}</button></Tab>
                                    <Tab><button>{tab2}</button></Tab>
                                    <Tab><button>{tab3}</button></Tab>
                                    {/* <Tab><button>{tab4}</button></Tab> */}
                                </TabList>
                            </div>
                        </div>
                    </div>
                    <TabPanel className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 mb-80 wow animate__fadeInUp" data-wow-duration=".6s">
                            <SingleProjectFour
                                categoryClass="project-4__category category-color-1"
                                TitleClass="project-4__title-sm title-color-1"
                                btnClass="project-4__link link-color-1"
                                Image={projectImg1}
                                Category="Mental Health Fitness"
                                Title="GameOn!"
                                Description="TheGameOnApp is a mental wellness app designed to connect you to expert mental wellness coaches and counselors."
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 mb-80 wow animate__fadeInUp" data-wow-duration=".9s">
                            <SingleProjectFour
                                categoryClass="project-4__category category-color-2"
                                TitleClass="project-4__title-sm title-color-2"
                                btnClass="project-4__link link-color-2"
                                Image={projectImg2}
                                Category="Attorney Services"
                                Title="AttorneyGram"
                                Description="Helping key decision makers feel understood, secure, and enabled to make productive and profitable decisions to power them forward in their law firm."
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 mb-80 wow animate__fadeInUp" data-wow-duration="1.1s">
                            <SingleProjectFour
                                categoryClass="project-4__category category-color-3"
                                TitleClass="project-4__title-sm title-color-3"
                                btnClass="project-4__link link-color-3"
                                Image={projectImg4}
                                Category="Education"
                                Title="Dr. Yves Salomon-Fernandez"
                                Description="My origin story, hmm? Well, where do I begin? Mine is an immigrant story.I can start from the point that our family emigrated from..."
                            />
                        </div>
                    </TabPanel> 
                    <TabPanel className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 mb-80 wow animate__fadeInUp" data-wow-duration=".6s">
                            <SingleProjectFour
                                categoryClass="project-4__category category-color-2"
                                TitleClass="project-4__title-sm title-color-2"
                                btnClass="project-4__link link-color-2"
                                Image={medilyze}
                                Category="Medical Services"
                                Title="MediLyze"
                                Description="MediLyze is your trusted chatbot companion, revolutionizing the way you manage your health.Describe your symptoms, and MediLyze instantly analyzes them to suggest potential treatments."
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 mb-80 wow animate__fadeInUp" data-wow-duration=".9s">
                            <SingleProjectFour
                                categoryClass="project-4__category category-color-1"
                                TitleClass="project-4__title-sm title-color-1"
                                btnClass="project-4__link link-color-1"
                                Image={projectImg5}
                                Category="Mental Health Fitness"
                                Title="GameOn!"
                                Description="TheGameOnApp is a mental wellness app designed to connect you to expert mental wellness coaches and counselors."
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 mb-80 wow animate__fadeInUp" data-wow-duration="1.1s">
                            <SingleProjectFour
                                categoryClass="project-4__category category-color-3"
                                TitleClass="project-4__title-sm title-color-3"
                                btnClass="project-4__link link-color-3"
                                Image={projectImg3}
                                Category="Education"
                                Title="Dr. Yves Salomon-Fernandez"
                                Description="My origin story, hmm? Well, where do I begin? Mine is an immigrant story.I can start from the point that our family emigrated from..."
                            />
                        </div>
                    </TabPanel> 
                    <TabPanel className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 mb-80 wow animate__fadeInUp" data-wow-duration=".6s">
                            <SingleProjectFour
                                categoryClass="project-4__category category-color-2"
                                TitleClass="project-4__title-sm title-color-2"
                                btnClass="project-4__link link-color-2"
                                Image={TheraHome}
                                Category="Therapy Services"
                                Title="TheraHome"
                                Description="The Therahome Therapy Service Application is a user-friendly platform designed to connect clients with experienced and qualified therapists."
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 mb-80 wow animate__fadeInUp" data-wow-duration=".9s">
                            <SingleProjectFour
                                categoryClass="project-4__category category-color-1"
                                TitleClass="project-4__title-sm title-color-1"
                                btnClass="project-4__link link-color-1"
                                Image={gameonui}
                                Category="Mental Health Fitness"
                                Title="GameOn!"
                                Description="TheGameOnApp is a mental wellness app designed to connect you to expert mental wellness coaches and counselors."
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 mb-80 wow animate__fadeInUp" data-wow-duration="1.1s">
                            <SingleProjectFour
                                categoryClass="project-4__category category-color-3"
                                TitleClass="project-4__title-sm title-color-3"
                                btnClass="project-4__link link-color-3"
                                Image={medilyze}
                                Category="Medical Services"
                                Title="MediLyze"
                                Description="MediLyze is your trusted chatbot companion, revolutionizing the way you manage your health.Describe your symptoms, and MediLyze instantly analyzes them to suggest potential treatments."
                            />
                        </div>
                    </TabPanel> 
                    {/* <TabPanel className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 mb-80 wow animate__fadeInUp" data-wow-duration=".6s">
                        <SingleProjectFour
                                categoryClass="project-4__category category-color-1"
                                TitleClass="project-4__title-sm title-color-1"
                                btnClass="project-4__link link-color-1"
                                Image={projectImg1}
                                Category="Mental Health Fitness"
                                Title="GameOn!"
                                Description="TheGameOnApp is a mental wellness app designed to connect you to expert mental wellness coaches and counselors."
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 mb-80 wow animate__fadeInUp" data-wow-duration=".9s">
                            <SingleProjectFour
                                categoryClass="project-4__category category-color-2"
                                TitleClass="project-4__title-sm title-color-2"
                                btnClass="project-4__link link-color-2"
                                Image={projectImg2}
                                Category="Attorney Services"
                                Title="AttorneyGram"
                                Description="Helping key decision makers feel understood, secure, and enabled to make productive and profitable decisions to power them forward in their law firm."
                            />
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 mb-80 wow animate__fadeInUp" data-wow-duration="1.1s">
                            <SingleProjectFour
                                categoryClass="project-4__category category-color-3"
                                TitleClass="project-4__title-sm title-color-3"
                                btnClass="project-4__link link-color-3"
                                Image={projectImg3}
                                Category="Education"
                                Title="Dr. Yves Salomon-Fernandez"
                                Description="My origin story, hmm? Well, where do I begin? Mine is an immigrant story."
                            />
                        </div>
                    </TabPanel>  */}

                </Tabs>
                <div className="row">
						<div className="col wow animate__fadeInUp" data-wow-duration="1.1s">
							<div className="project__details-btn text-center">
								<Link className="main-btn tp-btn-hover alt-color add-width" to="/contact"><span>Book a demo</span><b></b></Link>
							</div>
						</div>
				</div>
            </div>
        </div>
    );
}
export default Project;