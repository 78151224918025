import React from 'react';
//import { Link } from 'react-router-dom';

//import shapeImg1 from '../../assets/img/work/work-shape-4.png';
//import shapeImg2 from '../../assets/img/work/work-shape-5.png';
import shapeImg3 from '../../assets/img/work/work-shape-6.png';
import shapeImg4 from '../../assets/img/work/work-shape-7.png';
import android from '../../assets/img/svgs/android.svg';
//import android from '../../assets/img/service/android.png';
import apple from '../../assets/img/svgs/apple.svg';
import flutter from '../../assets/img/svgs/flutter.svg';
import react from '../../assets/img/svgs/react.svg';
import kotlin from '../../assets/img/svgs/kotlin.svg';
import swift from '../../assets/img/svgs/swift.svg';
import nodeJS from '../../assets/img/svgs/nodeJS.svg';
import java from '../../assets/img/svgs/java.svg';
import dotnet from '../../assets/img/service/dotnet.png';
import python from '../../assets/img/svgs/python.svg';
import angular from '../../assets/img/svgs/angular.svg';
import mysql from '../../assets/img/svgs/mysql.svg';
import mssql from '../../assets/img/svgs/mssql.svg';
import postgress from '../../assets/img/svgs/postgress.svg';
import oracle from '../../assets/img/svgs/oracle.svg';
import firebase from '../../assets/img/svgs/firebase.svg';
import mongodb from '../../assets/img/svgs/mongodb.svg';
const Solution = () => {

    return (
        <div className="solution-area solution__plr solution__space p-relative">
            {/* <div className="solution__shape-1 d-none d-lg-block">
                <img src={shapeImg1} alt="" />
            </div>
            <div className="solution__shape-2 d-none d-lg-block">
                <img src={shapeImg2} alt="" />
            </div> */}
            <div className="solution__shape-3 d-none d-lg-block">
                <img src={shapeImg3} alt="" />
            </div>
            <div className="solution__shape-4 d-none d-xxl-block">
                <img src={shapeImg4} alt="" />
            </div>
            <div className="container-fluid">
                <div className="solution__wrapper">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="skill__section-box text-center mb-50">
                                <h5 className="section-subtitle title-anim wow animate__fadeInUp" data-wow-duration="1.1s">What We Provide</h5>
                                <h4 className="section-title title-anim wow animate__fadeInUp" data-wow-duration="1.1s">Services We Offer</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 mb-40 wow animate__fadeInUp" data-wow-duration="1.1s">
                            <div className="solution-item">
                                <div className="solution-top text-center">
                                    <span>Mobile Development</span>
                                </div> 
                                <div className="solution-feature-list">
                                    <ul>
                                        <li><span><img src={android} width="25px" height="25px" alt="" />&emsp;Android</span> </li>
                                        <li><span><img src={apple} width="25px" height="25px" alt="" />&emsp;iOS</span> </li>
                                        <li><span><img src={flutter} width="25px" height="25px" alt="" />&emsp;Flutter</span> </li>
                                        <li><span><img src={react} width="25px" height="25px" alt="" />&emsp;React Native</span> </li>
                                        <li><span><img src={kotlin} width="25px" height="25px" alt="" />&emsp;Kotlin</span> </li>
                                        <li><span><img src={swift} width="25px" height="25px" alt="" />&emsp;Swift</span> </li>
                                    </ul>
                                </div> 
                                {/* <div className="solution-link color-1">
                                    <Link to="/about-us">See Discover More <i className="far fa-plus"></i></Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 mb-40 wow animate__fadeInUp" data-wow-duration="1.1s">
                            <div className="solution-item">
                                <div className="solution-top color-2 text-center">
                                    <span>Web Development</span>
                                </div> 
                                <div className="solution-feature-list">
                                    <ul>
                                        <li><span><img src={nodeJS} width="25px" height="25px" alt="" />&emsp;Node JS (Express or any library)</span> </li>
                                        <li><span><img src={java} width="25px" height="25px" alt="" />&emsp;Java (Sprint Boot, Hibernate)</span> </li>
                                        <li><span><img src={dotnet} width="25px" height="25px" alt="" />&emsp;.NET (MVC, Core)</span> </li>
                                        <li><span><img src={python} width="25px" height="25px" alt="" />&emsp;Python (Django, Flask)</span>  </li>
                                        <li><span><img src={angular} width="25px" height="25px" alt="" />&emsp;Angular</span>  </li>
                                        <li><span><img src={react} width="25px" height="25px" alt="" />&emsp;React</span>  </li>
                                    </ul>
                                </div> 
                                {/* <div className="solution-link color-2">
                                    <Link to="/about-us">See Discover More <i className="far fa-plus"></i></Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 mb-40 wow animate__fadeInUp" data-wow-duration="1.1s">
                            <div className="solution-item">
                                <div className="solution-top color-3 text-center">
                                    <span>Data Management</span>
                                </div> 
                                <div className="solution-feature-list">
                                    <ul>
                                        <li><span><img src={mysql} width="25px" height="25px" alt="" />&emsp;MySQL</span>  </li>
                                        <li><span><img src={mssql} width="25px" height="25px" alt="" />&emsp;MS SQL</span>  </li>
                                        <li><span><img src={postgress} width="25px" height="25px" alt="" />&emsp;Postgress SQL</span>  </li>
                                        <li><span><img src={oracle} width="25px" height="25px" alt="" />&emsp;Oracle</span>  </li>
                                        <li><span><img src={firebase} width="25px" height="25px" alt="" />&emsp;Google Firebase</span>  </li>
                                        <li><span><img src={mongodb} width="25px" height="25px" alt="" />&emsp;Mongo db</span>  </li>
                                    </ul>
                                </div> 
                                {/* <div className="solution-link color-3">
                                    <Link to="/about-us">See Discover More <i className="far fa-plus"></i></Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Solution;