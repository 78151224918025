// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// const firebaseConfig = {
//   apiKey: process.env.REACT_FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.FIREBASE_DATABASE_URL,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.FIREBASE_APP_ID,
//   measurementId: process.env.FIREBASE_MEASUREMENT_ID,
// };

const firebaseConfig = {
  apiKey: "AIzaSyB3UHf7FXQhcxteXv0RCYdtPPQ2wsTzNVY",
  authDomain: "fir-1-bef20.firebaseapp.com",
  databaseURL: "https://fir-1-bef20-default-rtdb.firebaseio.com",
  projectId: "fir-1-bef20",
  storageBucket: "fir-1-bef20.appspot.com",
  messagingSenderId: "868767244116",
  appId: "1:868767244116:web:1df4095dfef5176af672c1",
  measurementId: "G-3LM8LLF6BG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);