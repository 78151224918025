import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';


const Faq = (props) => {
    const { itemClass } = props;
	return(
        <div className={itemClass ? itemClass : 'it-custom-accordion'}>
            <h3>What Happens Next</h3>
            <Accordion className="accordion" preExpanded={'a'}>
                <AccordionItem className="accordion-items" uuid="a">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                            When will your team contact me?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>Our business development team contacts you within two working days</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-items" uuid="b">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                             What's in the discovery session?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>A discovery session is held to understand your project requirements</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-items" uuid="c">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                             How is my information protected?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>All the information shared is protected under the mNDA</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-items" uuid="d">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                             Next steps after project analysis?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>Our technical and business development teams analyze the scope of your project and share the best way forward in a proposal</p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-items" uuid="e">
                    <AccordionItemHeading>
                        <AccordionItemButton className="accordion-buttons">
                             When's the agreement signed?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion-body">
                        <p>A software development agreement is signed based on mutually agreed terms of the proposal</p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div> 
	)
}

export default Faq