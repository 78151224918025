import React from 'react';
import { Link } from 'react-router-dom';
import { useRef, useLayoutEffect, useEffect } from 'react';
import Brand from '../../components/Brand';
import Project from './ProjectSection';
import Feature from './FeatureSection';
import Banner from './HomeFourBanner';
import Skill from './SkillSection';
import Solution from './SolutionSection';
import Work from './WorkSection';
import WorkTwo from './WorkSectionTwo';
import Testimonial from './TestimonialSection';
import Blog from './BlogSection';
import Newsletter from './NewletterSection';
import Video from './VideoSection';
import Service from '../home/ServiceSection';
import Social from '../home/SocialSection';

const HomeFourMain = () => {
	const portfolioRef = useRef(null);
	useEffect(() => {
		const handleHashScroll = () => {
		if (window.location.hash === '#portfolio' && portfolioRef.current) {
			portfolioRef.current.scrollIntoView({ behavior: 'smooth' });
		}
		};
		window.addEventListener('hashchange', handleHashScroll);
    	handleHashScroll();
		return () => {
		window.removeEventListener('hashchange', handleHashScroll);
		};
	}, []);
	return (
		<main>
			<Banner />
			<Brand 
				itemClass="brand-2__area"
			/>
			<Service />
			{/* <Work /> */}
			
			{/* <Skill /> */}
			
			<Solution />
			
			<Social />
			{/* <Feature /> */}
			
			<WorkTwo />

			<div ref={portfolioRef} id='portfolio'>
				<Project />
			</div>
			
			<Testimonial />
			
			{/* <Video /> */}
			
			{/* <Newsletter /> */}
			
			{/* <Blog /> */}
		</main>
	);
}

export default HomeFourMain;