import React from 'react';
import { useState } from 'react';
import emailjs from 'emailjs-com';

import shapeImg1 from '../../assets/img/contact/ct-shape-1.png';
import shapeImg2 from '../../assets/img/contact/ct-shape-2.png';
import shapeImg3 from '../../assets/img/contact/ct-shape-3.png';
import shapeImg4 from '../../assets/img/contact/ct-shape-4.png';

const ContactTwo = (props) => {
	const { itemClass } = props;
    const [name, setName] = useState('');	
	const [email, setEmail] = useState('');	
	const [message, setMessage] = useState('');	
	const [success, setSuccess] = useState(null);
    const submitForm = async () => {
        console.log(name,email,message);
		const res = await fetch('https://api.web3forms.com/submit', {
			method: 'POST',
			body: JSON.stringify({
				access_key: '249007ae-fe16-4927-b51e-783c44d85ce9',
				name: name,
				email: email,
				message: message
				// Add parameters here
			}),
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
			},
		});

		if(res.status === 200) {
			setName('');
			setEmail('');
			setMessage('');
			setSuccess(true);
			return;
		}

		setSuccess(false);
	}
    const onNameHandler = event => {
		setName(event.target.value);
	}; 

	const onEmailHandler = event => {
		setEmail(event.target.value);
	}; 
	const onMessageHandler = event => {
		setMessage(event.target.value);
	}; 

	const disableSubmitBtn = Boolean(!name.length || !email.length || !message.length);

// default code//
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('', '', e.target, '')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });

        e.target.reset()
    } 
	return (
		<div className={itemClass ? itemClass : 'contact__area contact__plr-2 mt-50 mb-100 p-relative fix'}>
            {/* <div className="contact__shape-1 d-none d-lg-block">
                <img src={shapeImg1} alt="" />
            </div>
            <div className="contact__shape-2 d-none d-md-block">
                <img src={shapeImg2} alt="" />
            </div>
            <div className="contact__shape-3 d-none d-md-block">
                <img src={shapeImg3} alt="" />
            </div>
            <div className="contact__shape-4 d-none d-md-block">
                <img src={shapeImg4} alt="" />
            </div> */}
            <div className="contact__border">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-8 col-lg-7">
                            <div className="contact__section-title pb-10">
                                <h4 className="section-subtitle char-anim">Get In Touch</h4>
                                <h3 className="section-title char-anim">Let's talk about your project!</h3>  
                            </div>
                            <div className="contact__text">
                                <p className="char-anim-2">We would be happy to hear from you, please fill in the form below. </p> 
                            </div>
                            <div className="contact__form wow animate__fadeInUp" data-wow-duration="1.1s">
                                <form action="#" onSubmit={submitForm}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="postbox__contact-input">
                                            <input type="text" value={name} onChange={onNameHandler} fullWidth={true} id="name" placeholder="Your Name" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="postbox__contact-input">
                                            <input type="email" value={email} onChange={onEmailHandler} fullWidth={true} id="email" placeholder="Your Email" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="postbox__contact-textarea">
                                            <textarea value={message} onChange={onMessageHandler} fullWidth={true} id="message" placeholder="Message"></textarea>
                                        </div>
                                    </div>
                                </div>                    
                                </form>
                            </div>
                            <div className="contact__button wow animate__fadeInUp" data-wow-duration="1.1s">
                            <button className="main-btn-sm  tp-btn-hover alt-color" disabled={disableSubmitBtn} onClick={submitForm} type='submit'><span>Send Request</span><b></b></button>
                                {/* <a className="main-btn-sm  tp-btn-hover alt-color" href="#"><span>Send Request</span><b></b></a> */}
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className="contact__left-side">
                                <div className="contact__item d-flex align-items-center wow animate__fadeInUp" data-wow-duration="1.1s">
                                    <div className="contact__icon">
                                        <span><a href="#"><i className="fal fa-phone-alt"></i></a></span>
                                    </div>
                                    <div className="contact__content">
                                        <h4 className="contact__title-sm">Call This Now</h4>
                                        <span><a href="tel:+923341402463">+92-334-1402463 </a></span>
                                    </div>
                                </div>
                                <div className="contact__item d-flex align-items-center wow animate__fadeInUp" data-wow-duration="1.1s">
                                    <div className="contact__icon">
                                        <span><a href="#"><i className="fal fa-envelope-open-text"></i></a></span>
                                    </div>
                                    <div className="contact__content">
                                        <h4 className="contact__title-sm">Mail Us</h4>
                                        <span><a href="mailto:contact@sortup.dev">contact@sortup.dev</a></span>
                                    </div>
                                </div>
                                {/* <div className="contact__item d-flex align-items-center wow animate__fadeInUp" data-wow-duration="1.1s">
                                    <div className="contact__icon">
                                        <span><a href="#"><i className="fal fa-map-marker-check"></i></a></span>
                                    </div>
                                    <div className="contact__content">
                                        <h4 className="contact__title-sm">Your location</h4>
                                        <span><a href="tel:+025461556695">+025461556695 <br/>
                                            +826542556455</a></span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	);
}

export default ContactTwo;




