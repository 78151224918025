import React from 'react';

import featureImg1 from '../../assets/img/feature/fea-icon-1.png';
import featureImg2 from '../../assets/img/feature/fea-icon-2.png';
import featureImg3 from '../../assets/img/feature/fea-icon-3.webp';

const SocialFeed = (props) => {
    return (
        <div className="row justify-content-center">
            <div className="col-xxl-3 col-xl-4 col-md-6 col-lg-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
                <div className="social__item social__border-color-2 d-flex align-items-center justify-content-between">
                    <div className="social__content-box d-flex align-items-center">
                        <div className="social__icon">
                            <img src={featureImg1} width={30} alt="" />
                        </div>
                        <div className="social__text">
                            <h4 className="social__title mt-2"><a href='https://www.upwork.com/ag/sortup/' target='_blank'>Upwork</a></h4>
                        </div>
                    </div>
                    <div className="social__link social__color-1">
                        <a href='https://www.upwork.com/ag/sortup/' target='_blank'><span><i className="fal fa-arrow-right"></i></span></a>
                    </div>
                </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-md-6 col-lg-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
                <div className="social__item social__border-color-3 d-flex align-items-center justify-content-between">
                    <div className="social__content-box d-flex align-items-center">
                        <div className="social__icon">
                            <img className='rounded-5' src={featureImg2} width={30} alt="" />
                        </div>
                        <div className="social__text">
                            <h4 className="social__title mt-2"><a href='https://clutch.co/profile/sortup' target='_blank' >Clutch</a></h4>
                        </div>
                    </div>
                    <div className="social__link social__color-3">
                        <a href='https://clutch.co/profile/sortup' target='_blank'><span><i className="fal fa-arrow-right"></i></span></a>
                    </div>
                </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-md-6 col-lg-6 mb-30 wow animate__fadeInUp" data-wow-duration="1.1s">
                <div className="social__item social__border-color-4 d-flex align-items-center justify-content-between">
                    <div className="social__content-box d-flex align-items-center">
                        <div className="social__icon">
                            <img src={featureImg3} width={30} alt="" />
                        </div>
                        <div className="social__text">
                            <h4 className="social__title mt-2"><a href='https://pk.linkedin.com/company/sortup' target='_blank'>Linkedin</a></h4>
                        </div>
                    </div>
                    <div className="social__link social__color-4">
                        <a href='https://pk.linkedin.com/company/sortup' target='_blank'><span><i className="fal fa-arrow-right"></i></span></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SocialFeed;